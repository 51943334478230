// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MODAL */

.ant-tabs-bar {
  margin: 0 !important;
  padding-left: 20px;
}

.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  padding: 20px;
  background-color: #fff;
  border-color: #e8e8e8;
  border-width: 0 1px 1px 1px;
  border-style: solid;
}

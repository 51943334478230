// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN INPUT */

.ant-input-disabled {
  background: $gray-lighten-more-more;
}

.ant-typography.label {
  display: inline-block;
  padding: 0 15px 0 0;
  width: auto;
}

// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN FORM */

.ant-input {
  border: 1px solid #e4e9f0;
  border-radius: 0;
}
.ant-form input[type='file'] {
  min-height: 40px;
}

.ant-form input.ant-input-sm[type='file'] {
  min-height: 48px;
}

.ant-form input.ant-input-lg[type='file'] {
  min-height: 52px;
}

.ant-form-item-label {
  text-align: left;
  white-space: normal;
}

.ant-form-item > .ant-form-item,
.ant-form-item :not(.ant-form) > .ant-form-item {
  margin-top: -2px;
}

form .ant-select:only-child,
form .ant-cascader-picker:only-child {
  display: inline-block;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $menuBg;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $menuBg;
}

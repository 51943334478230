@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  CORE MODULE STYLES */

html {
  font-size: 13px;
  overflow-y: scroll;
  @media (max-width: $sm-max-width) {
    font-size: 12px;
  }
}

body {
  font-family: $base__font-family;
  color: $text-darken-more;
  background: $gray-lighten-more;
  line-height: 1.7;
  @media (max-width: $sm-max-width) {
    font-size: 12px;
  }
}

.utils__content {
  max-width: 100% !important;
}

:focus {
  outline: none !important;
}

svg {
  vertical-align: baseline;
  overflow: auto;
}

a {
  text-decoration: none;
  color: $text;
  @include transition-color();
  &:hover,
  &:active,
  &:focus {
    color: #3498db;
    text-decoration: none;
  }
}

input {
  outline: none !important;
  font-family: $base__font-family;
  color: $text-darken-more;
}

button,
input {
  box-shadow: none !important;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  appearance: none !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 50px white inset !important;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

// code element
code {
  color: $text;
  background: $gray-lighten-more;
  display: inline-block;
  padding: rem(2) rem(5);
  border-radius: 3px;
}

// pre element
pre {
  color: $text;
  padding: rem(20);
  border-radius: 3px;
  border: 1px solid $gray-border;
  background: $gray-lighten-more;
}

// ant design font overrides
button,
input,
select,
textarea {
  font-family: inherit;
}

// ant design button overrides
.ant-btn:hover,
.ant-btn:focus {
  color: #0055ae;
  border-color: #0055ae;
}

.ant-upload.ant-upload-select {
  width: 100%;
}

.ant-upload-list-item {
  height: 100% !important;
}

.ant-upload-list-item .ant-upload-list-item-name {
  white-space: normal;
  word-break: break-all;
}

// ant design form overrides
.ant-form-item {
  margin-bottom: 0;
}

.ant-form-item-label,
.ant-col.ant-form-item-control-wrapper,
#components-dropdown-demo-dropdown-button {
  float: left;
}

.ant-form-item-label,
.ant-form-item-control,
button.ant-btn.ant-dropdown-trigger span {
  line-height: 30px !important;
}

.ant-dropdown-trigger span {
  color: $white;
  font-size: 20px;
  border: 2px solid #ffffff;
  border-radius: 100%;
  padding: 5px;
}

.ant-col.ant-form-item-control-wrapper,
button.ant-btn.ant-dropdown-trigger {
  width: 100%;
}

.object-tab .ant-col.ant-form-item-control,
.object-tab .ant-col.ant-form-item-control-wrapper {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.object-tab .ant-col.ant-form-item-control {
  min-width: 83.333333%;
}

.ant-select-auto-complete.ant-select .ant-input {
  height: 30px;
}

.object-tab #components-dropdown-demo-dropdown-button {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

button.ant-btn.ant-dropdown-trigger {
  text-align: left;
}

button.ant-btn.ant-dropdown-trigger span.anticon.anticon-down {
  float: right;
  line-height: 34px;
}

.ant-select-auto-complete.ant-select .ant-input,
.ant-select-single .ant-select-selector,
.ant-dropdown-menu,
.ant-select-dropdown,
.ant-select span,
.ant-select li {
  border-radius: 0 !important;
}

.taxeren-tab .ant-typography {
  line-height: 30px;
}

// .taxeren-tab .ant-typography.label {
//   margin-left: 0px;
// }

// ant design table overrides
.adres-input img {
  margin-right: -15px;
  max-width: 200px;
}

.ant-table table {
  width: 100%;
  text-align: left;
  border: 1px solid #e8e8e8;
  border-bottom-width: 0;
  border-radius: 0;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 12px 16px;
  overflow-wrap: break-word;
  border-right: 1px solid #e8e8e8;
  color: rgba(0, 0, 0, 0.85);
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.ant-table-thead tr {
  background-color: rgba(0, 0, 0, 0.06);
}

.ant-table-thead > tr > th {
  font-weight: 700;
}

.ant-table-tbody > tr > td {
  font-weight: 400;
  padding: 8px 16px;
}

.ant-table-tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-table-tbody > tr > td:focus {
  outline: none !important;
}

.ant-table-tbody tr td .input {
  border: 1px solid #d9d9d9;
  width: 100%;
  display: block;
  padding: 3px 5px;
  min-height: 35px;
  line-height: 27px;
}

.ant-table-tbody .ant-form-item {
  margin-bottom: 0;
}

.ant-table-thead tr th.ant-table-row-cell-last,
.ant-table-tbody tr td.ant-table-row-cell-last {
  border-right: 0;
}

// ant design tabs override
.main-tab .ant-tabs-tab {
  padding: 6px 12px !important;
}

// carousel override
.swiper-container-coverflow {
  margin-left: -15px !important;
  margin-right: -15px !important;
  padding: 25px 0 !important;
}

.swiper-container-coverflow .swiper-button-prev,
.swiper-container-coverflow .swiper-button-next {
  height: 100%;
  width: auto;
  margin-top: 0;
  top: 0;
  padding: 0 15px;
  outline: none;
}

.swiper-container-coverflow .swiper-button-prev:hover,
.swiper-container-coverflow .swiper-button-next:hover {
  background-color: rgba(255, 255, 255, 0.35);
}

.swiper-container-coverflow .swiper-button-prev {
  right: auto;
  left: 0;
}

.swiper-container-coverflow .swiper-button-next {
  right: 0;
  left: auto;
}

// print
.print {
  display: none;
}

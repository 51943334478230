// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN BUTTON */

.ant-btn {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.ant-btn-primary {
  background-color: $menuBg;
  border-color: $menuBg;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #56bfe4;
  border-color: #56bfe4;
}
